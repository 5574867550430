<template>
  <div class="container">
    <h2 class="admin-reviews__title">Все поступившие отзывы</h2>
    <div class="admin-reviews__list">
      <MainLoader v-if="loading"/>
      <v-modal v-if="popUpIsVisible" :review-info="reviewInfo" @close="popUpIsVisible = false"/>
      <template v-for="(reviewInfo, index) in sortFunc" >
        <div
            :key="index + 'btns'"
            class="admin-reviews-bttns"
        >
          <button class="admin-reviews-bttn"
                  title="Удалить"
                  type="button"
                  :disabled="changingReview.value === true && changingReview.id === reviewInfo.id"
                  @click="deleteReview(reviewInfo.id, index)"
          >
            Удалить
          </button>
          <button class="admin-reviews-bttn"
                  type="button"
                  :disabled="changingReview.value === true && changingReview.id === reviewInfo.id"
                  @click="changeStatus(reviewInfo, index)"
          >
            {{ reviewInfo.approve ? 'Отклонить' : 'Одобрить' }}
          </button>

        <CardReviews
            :key="index"
            :review-info="reviewInfo"
            @click-inside="openPopUp(reviewInfo)"
            :loading="changingReview.value === true && changingReview.id === reviewInfo.id"
        />
        </div>
      </template>

    </div>
  </div>
</template>

<script>
import CardReviews from "@/components/mainPage/CardReviews";
import MainLoader from "@/components/MainLoader";
import {deleteReview, getReviews, putReview} from "@/API/reviews";
import VModal from "@/components/pages/vModal";

export default {
  name: "AdminReviews",
  components: {VModal, CardReviews, MainLoader, },

  data() {
    return {
      reviews: [],
      loading: false,
      changingReview: {value: false, id: null},
      popUpIsVisible: false,
      reviewInfo: null
    }
  },
  created() {
    this.getDataFromServer()
  },
  computed: {
    sortFunc() {
      return this.reviews.filter(() => true).sort((a, b) => new Date(b.date) - new Date(a.date))
    }
  },
  methods: {
    openPopUp(reviewInfo) {
      this.reviewInfo = reviewInfo
      this.popUpIsVisible = true
    },
    async getDataFromServer() {
      try {
        this.loading = true;
        this.reviews = await getReviews()
        this.loading = false;
      } catch (e) {
        this.loading = false;
      }
    },
    async changeStatus(review,index){
      try {
        const accept = confirm("Вы точно хотите одобрить/отклонить элемент?");
        if (accept && this.changingReview) {
        this.changingReview = {value: true, id: review.id};
        const res = await putReview({...review, approve: !review.approve});
        this.reviews.splice(index, 1, res);}
        this.changingReview = {value: false, id: review.id};
      } catch (e) {
        this.changingReview = {value: false, id: review.id};
        console.error(e);
      }
    },
    async deleteReview(id, index){
      try {
        const accept = confirm("Вы точно хотите удалить элемент?");
        if (accept && this.changingReview) {
          this.changingReview = {value: true, id: id};
          await deleteReview(id);
        this.reviews.splice(index, 1);}
        this.changingReview = {value: false, id: id};
      } catch (e) {
        this.changingReview = {value: false, id: id};
        console.error(e)
      }
    }
  }

}
</script>

<style lang="scss" scoped>
@import "src/assets/styles/variables";

.admin-reviews {
  &__list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 70px 80px;
  }

  &__title {
    color: $primary-color;
    font-size: $fontSizeXL;
    font-weight: 800;
    line-height: 33px;
    margin-bottom: 15px;
  }
}
.admin-reviews-bttn {
  width: 130px;
  height: 25px;
  background: #E3EAF1;
  border-radius: 5px;
  color: $primary-color;
  font-size: 20px;
  font-weight: 800;
  cursor: pointer;
  margin-left: 15px;
  margin-right: 15px;
  display: inline;
  justify-content: flex-end;
  align-items: center;
}
.admin-reviews-bttn:hover {
  background: #FFFFFF;
}

</style>
